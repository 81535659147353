import IC_Categories from './categories.svg';
import IC_Customer from './ic_customers.svg';
import IC_dashboard from './ic_dashboard.svg';
import IC_mail from './ic_mail.svg';
import IC_menu_item from './ic_menu_item.svg';
import IC_pricing from './ic_pricing.svg';
import IC_reservations from './ic_reservations.svg';
import IC_settings from './ic_settings.svg';
import IC_User from './ic_user.svg';
import IC_Vehicle from './ic_vehicle.svg';
import IC_branches from './ic_branches.svg';
import IC_calendar from './ic_calendar.svg';
import IC_Bank from './ic_bank.svg';
import IC_Expense from './ic_expense.svg';
import IC_Finance from './ic_finance.svg';
import IC_Invoice from './ic_invoice.svg';
import IC_permissions from './ic_permissions.svg';
import IC_Affiliation from './ic_affilaition.svg';
export { IC_Bank, IC_Categories, IC_Customer, IC_Expense, IC_Finance, IC_Invoice, IC_User, IC_Vehicle, IC_branches, IC_calendar, IC_dashboard, IC_mail, IC_menu_item, IC_permissions, IC_pricing, IC_reservations, IC_settings, IC_Affiliation, };
